
@font-face {
  font-family: 'printania-sans';
  font-weight: normal;
  font-style : regular;
  src: url('../public/files/project/fonts/PrintaniaSans-Regular.woff') format('woff'),
       url('../public/files/project/fonts/PrintaniaSans-Regular.ttf') format('ttf');
}
.title-font,
.content-font {
  font-family: 'Urbanist';
}

@font-face {
  font-family: 'printemps-2017';
  font-weight: normal;
  font-style : normal;
  src: url('../public/files/project/fonts/printemps2017.woff') format('woff'),
       url('../public/files/project/fonts/printemps2017.ttf') format('ttf');
}
@font-face {
  font-family: 'Urbanist';
  font-weight: normal;
  font-style : normal;
  src: url('../public/files/project/fonts/Urbanist-VariableFont_wght.ttf') format('truetype');

}

@font-face {
  font-family: 'Urbanist-SemiBold';
  font-weight: 600;
  font-style : normal;
  src: url('../public/mobigeo/public/fonts/Urbanist-SemiBold.ttf') format('truetype'),
  url('../public/mobigeo/public/fonts/Urbanist-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Urbanist-Bold';
  font-weight: 700;
  font-style : normal;
  src: url('../public/mobigeo/public/fonts/Urbanist-Bold.ttf') format('truetype'),
  url('../public/mobigeo/public/fonts/Urbanist-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Urbanist-ExtraBold';
  font-weight: 800;
  font-style : normal;
  src: url('../public/mobigeo/public/fonts/Urbanist-ExtraBold.woff2') format('woff2'),
  url('../public/mobigeo/public/fonts/Urbanist-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Urbanist-Black';
  font-weight: 900;
  font-style : normal;
  src: url('../public/mobigeo/public/fonts/Urbanist-Black.ttf') format('truetype'),
  url('../public/mobigeo/public/fonts/Urbanist-Black.woff2') format('woff2');
}


.icon-font {
  font-family: 'printemps-2017';
}